'use client'

import React, { useState } from 'react'
import Modal from '@modules/common/components/modal'
import PostalCodeCheckStep1 from '@modules/store/components/postal-code-check/postal-code-check-step1'
import PostalCodeCheckNoMatch from '@modules/store/components/postal-code-check/postal-code-check-no-match'
import PostalCodeCheckLimit from '@modules/store/components/postal-code-check/postal-code-check-limit'
import PostalCodeCheckValid from '@modules/store/components/postal-code-check/postal-code-check-valid'
import PostalCodeCheckExclusion from '@modules/store/components/postal-code-check/postal-code-check-exclusion'
import PostalCodeCheckThankYou from '@modules/store/components/postal-code-check/postal-code-check-thank-you'
import { useStore } from '@lib/context/store-context'
import X from '@modules/common/icons/x'

const PostcodeCheck = () => {
    const {
        postalCodeCheckResult,
        chosenPostalCode,
        showPostalCodeCheck,
        setShowPostalCodeCheck,
        setPostalCodeCheckResult,
        deliveryDayForChosenPostalCode,
        getProductBeforePostalCodeCheck,
        addProductBeforePostalCodeCheck,
        addItem,
        nextDeliveryDay,
    } = useStore()

    const [showEndScreen, setShowEndScreen] = useState(false)
    const addedItemBeforePostalCode = getProductBeforePostalCodeCheck()
    const formattedDate = nextDeliveryDay
        ? new Date(nextDeliveryDay.getTime() - nextDeliveryDay.getTimezoneOffset() * 60 * 1000)
        : undefined
    const value = formattedDate ? `${formattedDate.toISOString().split('T')[0]}` : undefined

    return (
        <div>
            <Modal isOpen={showPostalCodeCheck}>
                <div
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={() => {
                        if (showEndScreen || postalCodeCheckResult === 'valid') {
                            addedItemBeforePostalCode &&
                                addItem(
                                    addedItemBeforePostalCode,
                                    1,
                                    value ? { interval: '1', shipment_at: value } : {},
                                )
                            addProductBeforePostalCodeCheck(null)
                        } else {
                            setPostalCodeCheckResult(null)
                        }
                        setShowPostalCodeCheck(false)
                    }}
                >
                    <X size={20} />
                </div>
                {(!postalCodeCheckResult || postalCodeCheckResult === 'invalid') && !showEndScreen && (
                    <PostalCodeCheckStep1 postalCodeInvalid={postalCodeCheckResult === 'invalid'} />
                )}
                {postalCodeCheckResult === 'no_match' && chosenPostalCode && !showEndScreen && (
                    <PostalCodeCheckNoMatch postalCode={chosenPostalCode} onDone={() => setShowEndScreen(true)} />
                )}
                {postalCodeCheckResult === 'limit' && chosenPostalCode && !showEndScreen && (
                    <PostalCodeCheckLimit postalCode={chosenPostalCode} onDone={() => setShowEndScreen(true)} />
                )}
                {postalCodeCheckResult === 'valid' && !showEndScreen && (
                    <PostalCodeCheckValid
                        deliveryDay={deliveryDayForChosenPostalCode}
                        onClose={() => {
                            setShowPostalCodeCheck(false)
                            addedItemBeforePostalCode &&
                                addItem(
                                    addedItemBeforePostalCode,
                                    1,
                                    value ? { interval: '1', shipment_at: value } : {},
                                )
                            addProductBeforePostalCodeCheck(null)
                        }}
                    />
                )}
                {postalCodeCheckResult === 'exclusion' && chosenPostalCode && !showEndScreen && (
                    <PostalCodeCheckExclusion postalCode={chosenPostalCode} onDone={() => setShowEndScreen(true)} />
                )}
                {showEndScreen && (
                    <PostalCodeCheckThankYou
                        onClose={() => {
                            setShowPostalCodeCheck(false)
                            setPostalCodeCheckResult(null)
                            setTimeout(() => {
                                setShowEndScreen(false)
                            }, 500)
                            addedItemBeforePostalCode &&
                                addItem(
                                    addedItemBeforePostalCode,
                                    1,
                                    value ? { interval: '1', shipment_at: value } : {},
                                )
                            addProductBeforePostalCodeCheck(null)
                        }}
                    />
                )}
            </Modal>
        </div>
    )
}

export default PostcodeCheck
