import React from 'react'
import Button from '@modules/common/components/button'
import Confetti from "@modules/common/icons/confetti";

const PostalCodeCheckValid = ({onClose, deliveryDay }: {onClose: () => void, deliveryDay?: string}) => {
    return (
      <div className="content-container flex flex-col gap-y-4 pt-6 pb-12">
        <Confetti className="mx-auto" />
        <h1 className="font-poppins text-[20px] font-style: normal font-medium tracking-[0.15px]">
          We deliver to your area {deliveryDay ? <strong>{`on ${ deliveryDay }`}</strong> : '.'}
        </h1>
        <Button as={'button'} variant={'secondary'} onClick={() => onClose()}>
          Continue Shopping
        </Button>
      </div>
    )
}

export default PostalCodeCheckValid
