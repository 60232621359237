'use client'

import React from 'react'
import Button from '@modules/common/components/button'
import Input from '@modules/common/components/input'
import { useForm, get } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import SadFace from '@modules/common/icons/sad-face'
import { useStore } from '@lib/context/store-context'

const PostalCodeCheckNoMatch = ({ onDone, postalCode }: { onDone: () => void; postalCode: string }) => {
    const { createPostalCodeSignup, setPostalCodeCheckResult } = useStore()
    const schema = Yup.object({
        email: Yup.string().email('Please fill in a valid email').required('Please fill in your email'),
    })
    type FormSchema = Yup.InferType<typeof schema>
    const {
        formState: { errors },
        register,
        handleSubmit,
    } = useForm({ resolver: yupResolver(schema) })
    const error = get(errors, 'email')
    const onSubmit = (data: FormSchema) => {
        createPostalCodeSignup({
            code: postalCode,
            email: data.email,
            type: 'no_match',
        }).then(data => {
            if (data?.response?.status === 200) {
                onDone()
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="content-container flex flex-col gap-y-4 py-6">
            <SadFace className="mx-auto" />
            <h1 className="font-poppins text-[20px] font-style: normal font-medium tracking-[0.15px]">
                {`Oh, no! It looks like we aren't yet in your area.`}
            </h1>
            <p className="text-[14px] tracking-[0.25px]">
                If you’d like to be notified when we are, please enter your email below
            </p>
            <div className="">
                <Input type="text" label="Email" required {...register('email', { required: true })} />
            </div>
            {error && <div className="text-rose-500 text-small-regular py-2">{error?.message?.toString()}</div>}
            <Button as={'button'} variant={'secondary'} type={'submit'}>
                Submit
            </Button>
            <span className="font-poppins text-[14px] font-light">
                We take your privacy seriously. We will only use your email to notify you when we are able to serve your
                area.
            </span>
            <div
                onClick={() => setPostalCodeCheckResult(null)}
                className="font-poppins cursor-pointer text-[14px] underline font-light"
            >
                Check a different postal area
            </div>
        </form>
    )
}

export default PostalCodeCheckNoMatch
