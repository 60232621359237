import React from 'react'
import { IconProps } from 'types/icon'

const Car: React.FC<IconProps> = ({ ...props }) => (
    <svg width="148" height="91" viewBox="0 0 148 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M46.2504 64.8487C39.0676 64.8487 33.2467 70.6674 33.2467 77.8509C33.2467 85.0347 39.0676 90.8535 46.2504 90.8535C53.4356 90.8535 59.2551 85.0347 59.2551 77.8509C59.2551 70.6674 53.4356 64.8487 46.2504 64.8487ZM46.2504 83.5037C43.128 83.5037 40.5979 80.9733 40.5979 77.8509C40.5979 74.727 43.128 72.1981 46.2504 72.1981C49.3731 72.1981 51.905 74.727 51.905 77.8509C51.905 80.9733 49.3731 83.5037 46.2504 83.5037Z"
            fill="#F7410F"
        />
        <path
            d="M114.821 64.8487C107.638 64.8487 101.817 70.6674 101.817 77.8509C101.817 85.0347 107.638 90.8535 114.821 90.8535C122.006 90.8535 127.825 85.0347 127.825 77.8509C127.825 70.6674 122.006 64.8487 114.821 64.8487ZM114.821 83.5037C111.698 83.5037 109.169 80.9733 109.169 77.8509C109.169 74.727 111.698 72.1981 114.821 72.1981C117.944 72.1981 120.476 74.727 120.476 77.8509C120.476 80.9733 117.944 83.5037 114.821 83.5037Z"
            fill="#F7410F"
        />
        <path
            d="M60.8796 24.8954C60.8796 26.7174 59.3886 28.208 57.5669 28.208H11.8535C10.0314 28.208 8.54116 26.7174 8.54116 24.8954V22.9078C8.54116 21.0861 10.0318 19.5955 11.8535 19.5955H57.5669C59.389 19.5955 60.8796 21.0861 60.8796 22.9078V24.8954Z"
            fill="#F7410F"
        />
        <path
            d="M140.302 44.2901C135.534 43.0505 132.805 42.2712 130.933 38.6554L124.673 25.98C122.8 22.3642 117.935 19.4055 113.864 19.4055H105.301C105.301 19.4055 104.15 19.4296 104.15 18.2704C104.15 15.6126 104.15 7.63963 104.15 7.63963C104.15 3.51853 101.817 0.146545 96.8566 0.146545H27.071C19.9687 0.146545 16.5967 3.51889 16.5967 7.63963V14.3017C16.5967 14.3017 16.5967 16.2831 18.5145 16.2831H57.5669C61.2202 16.2831 64.1923 19.2549 64.1923 22.9081V24.8957C64.1923 28.549 61.2202 31.5207 57.5669 31.5207H18.5145C18.5145 31.5207 16.5967 31.3614 16.5967 33.4316V35.7226C16.5967 37.5447 19.0941 37.5436 19.0941 37.5436H45.3823C49.0355 37.5436 52.0076 40.5157 52.0076 44.1686V46.1562C52.0076 49.8094 49.0355 52.7812 45.3823 52.7812H19.5088C19.5088 52.7812 16.5967 52.7273 16.5967 55.046V71.144C16.5967 75.2648 19.9687 78.6371 24.0898 78.6371H28.2287C29.1674 78.6371 29.3057 78.1143 29.3057 77.8516C29.3057 68.5088 36.9076 60.9088 46.2507 60.9088C55.5946 60.9088 63.1961 68.5095 63.1961 77.8516C63.1961 78.1154 63.1212 78.6371 63.839 78.6371H97.2408C97.903 78.6371 97.8761 78.079 97.8761 77.8516C97.8761 68.5088 105.478 60.9088 114.821 60.9088C124.165 60.9088 131.766 68.5095 131.766 77.8516C131.766 78.1154 131.76 78.6371 132.174 78.6371H140.595C144.668 78.6371 148 75.3055 148 71.2328V57.1657C148 45.319 144.26 45.319 140.302 44.2901ZM126.212 43.6312C126.212 43.6312 110.487 43.6312 105.136 43.6312C104.265 43.6312 104.149 42.7835 104.149 42.7835V25.2268C104.149 25.2268 104.1 24.5526 105.218 24.5526H111.232C114.855 24.5526 119.183 27.1853 120.849 30.4027L126.42 41.6814C126.655 42.1355 126.906 42.5386 127.174 42.8999C127.372 43.1648 127.04 43.6312 126.212 43.6312Z"
            fill="#F7410F"
        />
        <path
            d="M48.6946 46.1562C48.6946 47.9782 47.204 49.4688 45.3823 49.4688H3.31256C1.49049 49.4688 0.000244141 47.9782 0.000244141 46.1562V44.1686C0.000244141 42.3469 1.49086 40.8563 3.31256 40.8563H45.3823C47.2043 40.8563 48.6946 42.3469 48.6946 44.1686V46.1562Z"
            fill="#F7410F"
        />
    </svg>
)

export default Car
