import React from 'react'
import { IconProps } from 'types/icon'

const SadFace: React.FC<IconProps> = ({ size = '94', ...props }) => (
    <svg width={size} height={size} viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M79.8976 13.6073C61.7568 -4.53504 32.242 -4.53504 14.1047 13.6029C-4.03608 31.7409 -4.03432 61.2562 14.1065 79.3976C32.242 97.5347 61.7568 97.5347 79.8958 79.3941C98.0349 61.2562 98.034 31.7426 79.8976 13.6073ZM74.8214 74.3215C59.4813 89.6613 34.5201 89.6631 19.1792 74.3232C3.83564 58.9807 3.83739 34.0172 19.1792 18.6773C34.5193 3.33834 59.4795 3.33658 74.8231 18.6791C90.1632 34.019 90.1614 58.9825 74.8214 74.3215ZM68.0343 64.889C68.6113 66.2227 67.9975 67.7704 66.6646 68.3465C65.3309 68.9252 63.7832 68.3097 63.2063 66.9768C60.6694 61.1115 54.6743 57.3208 47.9302 57.3208C41.0309 57.3208 34.9989 61.108 32.5656 66.9698C32.1456 67.9817 31.1661 68.5929 30.1358 68.5929C29.799 68.5929 29.4579 68.528 29.1274 68.3912C27.7857 67.8335 27.15 66.2938 27.7068 64.953C30.96 57.1209 38.8993 52.0605 47.9302 52.0605C56.7726 52.0605 64.6645 57.0954 68.0343 64.889ZM29.6473 33.9094C29.6473 30.907 32.0824 28.4719 35.0849 28.4719C38.0864 28.4719 40.5215 30.9061 40.5215 33.9094C40.5215 36.9135 38.0864 39.3477 35.0849 39.3477C32.0824 39.3477 29.6473 36.9135 29.6473 33.9094ZM54.3025 33.9094C54.3025 30.907 56.7393 28.4719 59.7417 28.4719C62.7433 28.4719 65.1783 30.9061 65.1783 33.9094C65.1783 36.9135 62.7441 39.3477 59.7417 39.3477C56.7393 39.3477 54.3025 36.9135 54.3025 33.9094Z"
            fill="#F7410F"
        />
    </svg>
)

export default SadFace
