import {ErrorMessage} from '@hookform/error-message'
import Eye from '@modules/common/icons/eye'
import EyeOff from '@modules/common/icons/eye-off'
import clsx from 'clsx'
import React, {useEffect, useImperativeHandle, useState} from 'react'
import {get} from 'react-hook-form'

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
    label: string
    errors?: Record<string, unknown>
    touched?: Record<string, unknown>
    name: string
    disabled?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({type, name, label, errors, touched, disabled, required, ...props}, ref) => {
        const inputRef = React.useRef<HTMLInputElement>(null)
        const [showPassword, setShowPassword] = useState(false)
        const [inputType, setInputType] = useState(type)

        useEffect(() => {
            if (type === 'password' && showPassword) {
                setInputType('text')
            }

            if (type === 'password' && !showPassword) {
                setInputType('password')
            }
        }, [type, showPassword])

        useImperativeHandle(ref, () => inputRef.current!)

        const hasError = get(errors, name) && get(touched, name)


        return (
            <>
                <div className="relative z-0 w-full text-base-regular">
                    <input
                        type={inputType}
                        name={name}
                        placeholder=" "
                        className={clsx(
                            'pt-2.5 lg:pt-4 pb-[2px] block caret-weekly-red w-full rounded px-4 mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-0 focus:border-weekly-red border-gray-200',
                            {
                                'border-rose-500 focus:border-rose-500': hasError,
                            },
                            {
                                'pointer-events-none opacity-75': disabled,
                            },
                        )}
                        {...props}
                        ref={inputRef}
                    />
                    <label
                        htmlFor={name}
                        onClick={() => inputRef.current?.focus()}
                        className={clsx(
                            'mx-3 px-1 transition-all absolute duration-300 top-2 lg:top-3 -z-1 origin-0 text-gray-500',
                            {
                                '!text-rose-500': hasError,
                            },
                        )}
                    >
                        {label}
                        {required && <span className="text-rose-500">*</span>}
                    </label>
                    {type === 'password' && (
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="text-gray-400 px-4 focus:outline-none transition-all duration-150 outline-none focus:text-gray-700 absolute right-0 top-3"
                        >
                            {showPassword ? <Eye/> : <EyeOff/>}
                        </button>
                    )}
                </div>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({message}) => {
                        return (
                            <div className="pb-2 pt-1 pl-2 text-rose-500 text-xsmall-regular">
                                <span>{message}</span>
                            </div>
                        )
                    }}
                />
            </>
        )
    },
)

Input.displayName = 'Input'

export default Input
