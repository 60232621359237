import { Dialog, Transition } from '@headlessui/react'
import { ModalProvider, useModal } from '@lib/context/modal-context'
import X from '@modules/common/icons/x'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import useToggleState from '@lib/hooks/use-toggle-state'

type ModalProps = {
    isOpen: boolean
    close?: () => void
    size?: 'small' | 'medium' | 'large'
    children: React.ReactNode
}

type ModalChildProps = {
    className?: string
    style?: React.CSSProperties
    children?: React.ReactNode

}

const Modal: React.FC<ModalProps> & {
    Title: React.FC<ModalChildProps & { close: () => void }>
    Description: React.FC<ModalChildProps>
    Body: React.FC<ModalChildProps>
    Footer: React.FC<ModalChildProps>
} = ({ isOpen, close, size = 'medium', children }) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[75]" onClose={() => close ? close() : undefined}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full h-full items-center justify-center lg:p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={clsx(
                                    'justify-center overflow-auto w-full rounded transform bg-white lg:p-10 text-left align-middle shadow-xl transition-all h-fit max-h-[60vh]',
                                    {
                                        'max-w-md': size === 'small',
                                        'max-w-xl': size === 'medium',
                                        'max-w-3xl': size === 'large',
                                    },
                                )}
                            >
                                <ModalProvider close={() => close ? close() : undefined}>{children}</ModalProvider>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const Title: React.FC<React.PropsWithChildren & { close: () => void }> = ({ close, children }) => {
    return (
        <Dialog.Title className="flex items-center justify-between px-4">
            <div className="text-large-semi">{children}</div>
            <div className="absolute right-10 top-10">
                <button onClick={close}>
                    <X size={20} />
                </button>
            </div>
        </Dialog.Title>
    )
}

const Description: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Dialog.Description className="flex text-small-regular text-gray-700 items-center justify-center pt-2 pb-4 h-full">
            {children}
        </Dialog.Description>
    )
}

const Body: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <div className="flex-1">{children}</div>
}

const Footer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <div className="flex items-center justify-end gap-x-4">{children}</div>
}

Modal.Title = Title
Modal.Description = Description
Modal.Body = Body
Modal.Footer = Footer

export default Modal
