import React from 'react'
import Button from '@modules/common/components/button'
import Confetti from '@modules/common/icons/confetti'

const PostalCodeCheckThankYou = ({ onClose }: { onClose: () => void }) => {
    return (
        <div className="content-container flex flex-col gap-y-4 pt-6 pb-12">
            <Confetti className="mx-auto" />
            <h1 className="font-poppins text-[20px] font-style: normal font-medium tracking-[0.15px]">
                Thanks for registering, we will be in touch with any updates.
            </h1>
            <Button as={'button'} variant={'secondary'} onClick={() => onClose()}>
                Continue browsing
            </Button>
        </div>
    )
}

export default PostalCodeCheckThankYou
