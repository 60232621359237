'use client'

import React, { useEffect } from 'react'
import Button from '@modules/common/components/button'
import Input from '@modules/common/components/input'
import { useForm } from 'react-hook-form'
import Car from '@modules/common/icons/car'
import Link from 'next/link'
import { useStore } from '@lib/context/store-context'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ErrorMessage } from "@hookform/error-message";

type MessageProps = {
  type: 'success' | 'error'
  message: string
}

const PostalCodeCheckStep1 = ({ postalCodeInvalid }: { postalCodeInvalid: boolean }) => {
  const schema = Yup.object({
    postal_code: Yup.string().required('Please fill in a postal_code'),
  })
  type FormSchema = Yup.InferType<typeof schema>
  const { register, handleSubmit, setError, formState: { errors } } = useForm({ resolver: yupResolver(schema) })
  const { validatePostalCode } = useStore()

  useEffect(() => {
    if (postalCodeInvalid) {
      setError('postal_code', { type: 'error', message: 'The entered postal code is invalid' })
    }
  }, [postalCodeInvalid]);

  const handleConfirm = (data: FormSchema) => {
    validatePostalCode(data.postal_code)
  }

  return (
    <div className="content-container flex flex-col gap-y-4 py-6">
      <Car className="mx-auto" />
      <h1 className="font-poppins text-[20px] font-medium tracking-[0.15px]">Check if we deliver to your area</h1>
      <form id="postCode-form" onSubmit={ handleSubmit(handleConfirm) } className={ 'flex flex-col gap-y-4' }>
        <div>
          <Input type="text" label="Postcode" required { ...register('postal_code', { required: true }) } />
          <ErrorMessage
            errors={ errors }
            name="postal_code"
            render={ ({ message }) => <p className="text-sm text-red-700 mt-1">{ message }</p> }
          />
        </div>

        <Button as={ 'button' } form="postCode-form" variant={ 'secondary' } type="submit">
          Check
        </Button>
      </form>
      <div className="h-px w-full border-b border-gray-200 border-dashed my-4" />
      <span>
                Already a customer?{ ' ' }
        <Link href="/account/login" className="underline text-[#0034EE] font-poppins font-[16px]">
                    Log in
                </Link>
            </span>
    </div>
  )
}

export default PostalCodeCheckStep1
